import { useViewport } from '@/contexts/ViewportContext'
import Trans from 'next-translate/Trans'
import { FC } from 'react'
import { Grid, LogoIconOnWhite, Text } from 'ui-v2'
import { containerCx, noWrapTextCx } from './FlatTextCTA.css'

export interface FlatTextCTAProps {
  titleI18nKey?: string
  className?: string
}

const DynamicLogo = () => {
  const { width } = useViewport()
  const isSmallScreen = width < 601
  const logoSize = isSmallScreen ? 60 : 120

  return <LogoIconOnWhite height={logoSize} width={logoSize} />
}

export const d = {
  defaultTitle: 'tensor-website-common:cta.title',
}

export const FlatTextCTA: FC<React.PropsWithChildren<FlatTextCTAProps>> = ({
  titleI18nKey,
  children,
  className,
}) => (
  <Grid className={containerCx}>
    <DynamicLogo />
    <Text variant="displayMd" as="h2" className={className}>
      <Trans
        i18nKey={titleI18nKey ?? d.defaultTitle}
        components={{
          br: <br />,
          nowrap: <span className={noWrapTextCx} />,
        }}
      />
    </Text>
    {children}
  </Grid>
)

import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'
import { Box, Grid } from 'ui-v2'
import { innerWrapperCx, outerWrapperCx } from './CTASectionWrapper.css'

export interface CTASectionWrapperProps extends PropsWithChildren {
  className?: string
}
export const CTASectionWrapper: FC<CTASectionWrapperProps> = ({ children, className }) => (
  <Grid as="section" className={clsx(outerWrapperCx, className)}>
    <Box className={innerWrapperCx} as="article">
      {children}
    </Box>
  </Grid>
)

import { FC } from 'react'
import { logoOnWhite } from './Logos.css'
import { ResizableLogoProps } from './types'

export interface LogoIconOnWhiteProps extends ResizableLogoProps {}

const DEFAULT_HEIGHT = 60
const DEFAULT_WIDTH = 60
export const LogoIconOnWhite: FC<LogoIconOnWhiteProps> = ({
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="logo-icon-on-white"
    aria-labelledby="Tensor Logo"
  >
    <title>Tensor Logo</title>
    <g transform={`scale(${width / DEFAULT_WIDTH})`} className={logoOnWhite} opacity="0.25">
      <path
        d="M30.854 52.875H11.4929C10.4746 52.875 9.89954 52.3452 10.2085 51.6916L15.1176 41.3084C15.4266 40.6548 16.5027 40.125 17.521 40.125H36.8821C37.9004 40.125 38.4755 40.6548 38.1665 41.3084L33.2574 51.6916C32.9484 52.3452 31.8723 52.875 30.854 52.875Z"
        fill="#4082FF"
      />
    </g>
    <g transform={`scale(${width / DEFAULT_WIDTH})`} className={logoOnWhite} opacity="0.5">
      <path
        d="M38.5652 43.125H7.38116C6.35295 43.125 5.77236 42.5796 6.08432 41.9068L12.2582 28.5932C12.5702 27.9204 13.6566 27.375 14.6848 27.375H45.8688C46.897 27.375 47.4776 27.9204 47.1657 28.5932L40.9918 41.9068C40.6798 42.5796 39.5934 43.125 38.5652 43.125H38.5652Z"
        fill="#4082FF"
      />
    </g>
    <g transform={`scale(${width / DEFAULT_WIDTH})`} className={logoOnWhite}>
      <path
        d="M47.8668 31.125H5.12853C4.10228 31.125 3.52279 30.5685 3.83416 29.882L11.2112 13.618C11.5226 12.9315 12.607 12.375 13.6332 12.375H56.3715C57.3977 12.375 57.9772 12.9315 57.6658 13.618L50.2888 29.882C49.9774 30.5685 48.893 31.125 47.8668 31.125H47.8668Z"
        fill="#4082FF"
      />
    </g>
  </svg>
)

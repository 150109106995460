import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'
import { containerCx } from './Container.css'

export interface ContainerProps extends PropsWithChildren {
  as?: React.ElementType
  className?: string
}

export const Container: FC<ContainerProps> = ({ className, as: Element = 'div', children }) => (
  <Element className={clsx(containerCx, className)}>{children}</Element>
)
